import * as React from 'react';
import { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import { auth } from '../../firebase';
import Login from '../Login';
import Home from '../Home';

function App() {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = createTheme({
    palette: {
      mode: 'dark', // Switching the dark mode on is a single property value change.
    },
  });

  const [user, setUser] = useState(null);
  const [init, setInit] = useState(false);

  auth.onAuthStateChanged((user) => {
    setUser(user);
    setInit(true);
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!init &&
        <CircularProgress size={24} />
      }
      {init && !user &&
        <Login />
      }
      {init && user &&
        <Home user={user} />
      }
    </ThemeProvider>
  );

}

export default App;
