import React from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { CardHeader } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import './equipment.css';

function Equipment(props) {

    const data = props.data;

    const useStyles = makeStyles(theme => ({
        green: {
            backgroundColor: "#2E7D32"
        },
        blinkGreen: {
            animation: "blinkGreen 2s infinite alternate"
        },
        yellow: {
            backgroundColor: "#827717"
        },
        blinkYellow: {
            animation: "blinkYellow 2s infinite alternate"
        },
        red: {
            backgroundColor: "#B00020"
        },
        brinkRed: {
            animation: "blinkRed 2s infinite alternate"
        },
        brown: {
            backgroundColor: "#5D4037"
        },
        blinkBrown: {
            animation: "blinkBrown 2s infinite alternate"
        },
        stateTimeout: {
            color: "#ff8a65"
        }
    }));
    const classes = useStyles();

    const TIMEOUT = 180000;
    const PATLITE_OUT_OF_RANGE = 'なし';
    // const TEMPERATURE_OUT_OF_RANGE = -273.16;
    // const WATERLEVEL_OUT_OF_RANGE = -1;

    // 稼働
    let p_color = null;
    let cardHeaderClass = null;
    let p_state = PATLITE_OUT_OF_RANGE;

    let raw_green = null;
    let raw_yellow = null;
    let raw_red = null;

    if (data.patlite && moment().diff(data.patlite.time) > TIMEOUT) {
        p_state = '通信断';
        cardHeaderClass = { subheader: classes.stateTimeout };
        raw_green = data.patlite.raw_green;
        raw_yellow = data.patlite.raw_yellow;
        raw_red = data.patlite.raw_red;
    } else if (data.patlite) {
        const patlite = data.patlite;
        if (patlite.green > 0 && !(patlite.green === 100 && patlite.yellow > 0)) {
            p_state = '稼働';
            p_color = classes.green;
            if (patlite.green === 50) {
                p_color = classes.blinkGreen;
            }
        } else if (patlite.green === 100 && patlite.yellow > 0) {
            p_state = 'パレット交換中';
            p_color = classes.brown;
        } else if (patlite.yellow > 0) {
            p_state = '停止';
            p_color = classes.yellow;
            if (patlite.yellow === 50) {
                p_color = classes.blinkYellow;
            }
        } else if (patlite.red > 0) {
            p_state = '異常';
            p_color = classes.red;
            if (patlite.red === 50) {
                p_color = classes.brinkRed;
            }
        } else {
            p_state = '待機';
        }
        raw_green = patlite.raw_green;
        raw_yellow = patlite.raw_yellow;
        raw_red = patlite.raw_red;
    }

    // let temperature = TEMPERATURE_OUT_OF_RANGE;
    // if (data.temperature && moment().diff(data.temperature.time) < TIMEOUT) {
    //     temperature = Number.parseFloat(data.temperature.value).toFixed(1);
    // }

    // let waterlevel = WATERLEVEL_OUT_OF_RANGE;
    // if (data.waterlevel && moment().diff(data.waterlevel.time) < TIMEOUT) {
    //     waterlevel = Number.parseInt(data.waterlevel.value);
    // }

    //    <Grid container direction="row" justify="space-between" alignItems="center">
    //    <Grid item>
    //        <Typography>{temperature !== TEMPERATURE_OUT_OF_RANGE ? temperature : '--'} &deg;C</Typography>
    //    </Grid>
    //    {waterlevel !== WATERLEVEL_OUT_OF_RANGE &&
    //        <Grid item>
    //            <Typography>{waterlevel} mm</Typography>
    //        </Grid>
    //    }
    //    </Grid>

    return (
        <Card className={p_color}>
            <CardHeader classes={cardHeaderClass} title={data.equipment_name} subheader={p_state} />
            <CardContent>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography>g: {raw_green}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>y: {raw_yellow}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>r: {raw_red}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );

}

export default Equipment;