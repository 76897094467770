import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import { BottomNavigation } from '@mui/material';
import { BottomNavigationAction } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { auth, firestore } from '../../firebase';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import Equipment from '../Equipment';

function Home(props) {

    // const [loading, setLoading] = useState(true);
    const [equipments, setEquipments] = useState(null);

    const useStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: theme.spacing(1),
        },
        main: {
            padding: theme.spacing(1)
        },
        bottomNavigation: {
            marginTop: 'auto',
        }
    }));
    const classes = useStyles();
    // const user = props.user;

    const signOut = () => {
        console.log("signOut");
        auth.signOut();
    }

    useEffect(() => {
        document.title = "ダッシュボード";
        const q = query(collection(firestore, "equipments"), orderBy("company_id"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const equips = [];
            snapshot.forEach((doc) => {
                let equip = doc.data();
                equip['id'] = doc.id;
                equips.push(equip);
            });
            setEquipments(equips);
            return () => unsubscribe();
        });
    }, []);

    return (
        <div className={classes.root}>
            <Container maxWidth={false} className={classes.main}>
                <Grid container spacing={1}>
                    {equipments &&
                        equipments.map((data) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={data.id}>
                                <Equipment data={data} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
            <BottomNavigation className={classes.bottomNavigation}>
                <BottomNavigationAction label="ログアウト" onClick={signOut} icon={<ExitToAppIcon />} />
            </BottomNavigation>
        </div>
    );

}

export default Home;